<template>
    <div class="btn-action-md">
      <b-button class="btn-detail mr-1" title="Editar usuario" v-b-tooltip.hover.bottom.noninteractive
        @click="openModalEditar()"
        v-if="$editar(['Usuarios'])"><font-awesome-icon :icon="{ prefix: 'fa', iconName: 'edit', }" class="icon btn-icon" /></b-button>
      <b-button class="btn-detail" title="Eliminar usuario" v-b-tooltip.hover.bottom.noninteractive
        @click="openModalEliminar()"
        v-if="$eliminar(['Usuarios'])"><font-awesome-icon :icon="{ prefix: 'fa', iconName: 'trash-alt', }" class="icon btn-icon" /></b-button>
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: [
  ],
  data() {
    return {
    };
  },
  methods: {
    openModalEditar() {
      this.params.context.componentParent.openModalEditar(this.params.data);
    },
    openModalEliminar() {
      this.params.context.componentParent.openModalEliminar(this.params.data);
    },
  },
});
</script>

<style lang="scss" scoped>
.btn-icon {
  padding: 0 .30rem;
  width: 1.4rem !important;
  height: auto;
}
.f-btn {
  display: inline-flex;
  vertical-align: text-bottom;
}
</style>
