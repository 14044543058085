<template>
  <b-form-group
      v-slot="{ ariaDescribedby }"
      class="ag-filter"
    >
      <b-form-checkbox :checked="checkAll" :indeterminate="indeterminate" @change="onCheckAllChange">(TODOS)</b-form-checkbox>
      <b-form-checkbox-group
        v-model="values"
        :options="options"
        :aria-describedby="ariaDescribedby"
        name="checkfilter"
        stacked
        @change="onChange"
      ></b-form-checkbox-group>
    </b-form-group>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      indeterminate: false,
      checkAll: true,
      options: [],
      values: [],
      filter: 'equals',
      valueGetter: null,
      locale: 'AG_GRID_LOCALE_ES',
    };
  },
  created() {
    this.valueGetter = this.params.valueGetter;
  },
  mounted() {
    // eslint-disable-next-line default-case
    switch (this.params.column.colId) {
      case 'EsActivo':
        this.options = ['ACTIVO', 'INACTIVO'];
        break;
      case 'EsProveedor':
        this.options = ['SI', 'NO'];
        break;
      case 'EsInterno':
        this.options = ['SI', 'NO'];
        break;
      case 'EsRepse':
        this.options = ['SI', 'NO'];
        break;
    }
    this.values = this.options;
  },
  methods: {
    applyFilter(/* $event */) {
      this.params.filterChangedCallback();
    },
    clearFilter(/* $event */) {
      // this.values = this.options;
      this.filter = 'equals';
      this.applyFilter();
    },
    isFilterActive() {
      return this.values !== null && this.values !== undefined && this.values !== '' && this.values.length !== 0;
    },

    doesFilterPass(/* params */) {
      return !this.values; /* || this.value.toLowerCase()
                    .split(" ")
                    .every((filterWord) => {
                        return this.valueGetter(params.node).toString().toLowerCase().indexOf(filterWord) >= 0;
                    }); */
    },
    getModel() {
      if (this.values === null) return null;
      const model = {
        type: this.filter,
        filterType: 'checkbox',
        filter: this.values.length !== 0 ? this.values : [
          '%%',
        ], // '%%' es un simbolo para forzar a pedir cero datos de la tabal
      };
      return model;
    },
    setModel(model) {
      if (model === null) {
        this.values = this.options;
        this.indeterminate = false;
        this.checkAll = true;
      } else {
        this.values = model.value;
      }
    },
    afterGuiAttached() {},
    // Metodo al elegir un checkbox
    onChange(checkedList) {
      this.indeterminate = !!checkedList.length && checkedList.length < this.options.length;
      this.checkAll = checkedList.length === this.options.length;
      this.applyFilter();
    },
    // Metodo al seleccionar todos los checkbox
    onCheckAllChange(checked) {
      Object.assign(this, {
        values: checked ? this.options : [],
        indeterminate: false,
        checkAll: checked,
      });

      this.clearFilter();
    },
  },
});
</script>
